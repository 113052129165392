









































































































import {Component} from 'vue-property-decorator';
import {dispatchDeleteUser, dispatchGetUsersForList} from '@/store/crud/actions';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';
import {IUser} from '@/interfaces/users';

@Component({components: {ConfirmationModal}})
export default class AdminUsers extends AppComponent {
  public headers = [
    {
      text: 'First Name',
      sortable: true,
      value: 'fname',
      align: 'left',
    },
    {
      text: 'Last Name',
      sortable: true,
      value: 'lname',
      align: 'left',
    },
    {
      text: 'Email',
      sortable: true,
      value: 'email',
      align: 'left',
    },
    {
      text: 'Is Active',
      sortable: true,
      value: 'isActive',
      align: 'left',
    },
    {
      text: 'Is Physician',
      sortable: true,
      value: 'isPhysician',
      align: 'left',
    },
    {
      text: 'Is Superuser',
      sortable: true,
      value: 'isSuperuser',
      align: 'left',
    },
    {
      text: 'Is Nurse',
      sortable: true,
      value: 'isNursePracticioner',
      align: 'left',
    },
    {
      text: 'Is Reviewer',
      sortable: true,
      value: 'isReviewer',
      align: 'left',
    },
    {
      text: 'Is Creator',
      sortable: true,
      value: 'isCreator',
      align: 'left',
    },
    {
      text: 'Actions',
      value: 'id',
      align: 'center',
    },
  ];

  public search = '';
  public fetchingUserData = false;

  public listOfUsers: IUser[] = [];

  public async mounted() {
    this.fetchData();
  }

  public async fetchData() {
    this.fetchingUserData = true;
    await this.$nextTick();
    this.listOfUsers = await dispatchGetUsersForList(this.$store);
    this.fetchingUserData = false;
  }

  /**
   * Delete a user and refresh the screen
   */
  public async deleteUser(userId: string) {
    await dispatchDeleteUser(this.$store, {id: userId});
    this.fetchData();
  }
}
